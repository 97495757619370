import { Typography, Table, TableBody, TableCell, TableRow, FormControl, Select, InputLabel, MenuItem, Box, Pagination, Button, Avatar } from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';
import { useEffect } from "react";
import { Link } from "react-router-dom";

import { fNumber } from '../util';
import { treatments } from '../data';

export default function Home(props) {
  const {
    hospitals,
    selected,
    page,
    setPage,
    total,
    treatment,
    setTreatment
  } = props;

  const handleChange = (e) => {
    setTreatment(e.target.value);
  };

  const handlePageChange = (e, p) => {
    setPage(p);
  }

  return (
    <Box alignItems="flex-start">
      <FormControl variant="standard" sx={{width: "20%", minWidth: 200, my: 2}}>
        <InputLabel>분류</InputLabel>
        <Select
          value={treatment}
          onChange={handleChange}
        >
          {treatments.map((treatment) => <MenuItem key={`treatment-${treatment.label}`} value={treatment.value}>{treatment.label}</MenuItem>)}
        </Select>
      </FormControl>
      <Box>
        {(Boolean(selected) ? [selected] : hospitals).map((hospital, idx) => {
          return (
            <Button
              key={`hospital-${idx}-${hospital.id}`}
              component={Link}
              to={`/item/${hospital.id}`}
              sx={{textDecoration: 'none', my: 1}}
              display="flex"
              color="inherit"
              p={1}
              fullWidth
            >
              <Box sx={{flexGrow: 1}}>
                <Typography variant="h5">{hospital.name}</Typography>
                <Typography variant="body1" color="textSecondary">{hospital.addr} {hospital.id}</Typography>
              </Box>
              <Box sx={{wordBreak: 'keep-all', ml: 1}}>
                <Typography variant="h6">{fNumber(hospital.price_min)}원</Typography>
              </Box>
            </Button>
          )
        })}
        {hospitals.length == 0 && <Box py={2} display="flex" flexDirection="column" alignItems="center">
          <img src="/search.png" style={{width: 120, height: 120, marginBottom: 16, opacity: 0.3}} />
          <Typography variant="h6" color="textSecondary">해당 지역에 검색된 병원이 없습니다.</Typography>
        </Box>}
      </Box>
      {(total > 1 && !selected) && <Box alignItems="center" my={2}>
        <Pagination
          count={total}
          page={page}
          color="primary"
          onChange={handlePageChange}
        />
      </Box>}
    </Box>
  );
};