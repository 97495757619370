export const treatments = [
  {
    value: '0',
    label: '1모당'
  },
  {
    value: '1',
    label: '500모 미만'
  },
  {
    value: '2',
    label: '500모 ~ 1,000모'
  },
  {
    value: '3',
    label: '1,000모 ~ 2,000모'
  },
  {
    value: '4',
    label: '2,000모 이상'
  }
];