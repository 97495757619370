import { Grid, Avatar, Box, Typography, Link } from "@mui/material";

export default function Footer () {
  return (
    <Box color="#fff" bgcolor="#222">
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box m={3} display="flex" alignItems="center">
            <Avatar
              src="./favicon-32x32.png"
              sx={{ mr: 1, width: 28, height: 28 }}
            />
            <Typography variant="h4">모발이식웨건</Typography>
          </Box>
          <Box m={3}>
            <Typography>© 모발이식웨건 2022. All rights reserved.</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box m={3}>
            <Typography>제휴 및 협력 문의</Typography>
            <Typography component={Link} href="mailto:agile.data.service@gmail.com">agile.data.service@gmail.com</Typography>
          </Box>
          <Box m={3}>
            <Typography>데이터 출처</Typography>
            <Typography component={Link} target="_blank" href="https://www.hira.or.kr">건강보험심사평가원</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};