import { Link, useNavigate } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import { AppBar, Toolbar, Box, Typography, InputBase, Avatar, Stack } from "@mui/material";

export default function Header () {
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Stack direction="row" onClick={() => navigate('/')} sx={{cursor: "pointer"}}>
            <Avatar
              src="./favicon-32x32.png"
              sx={{ mr: 1, width: 28, height: 28 }}
            />
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              모발이식웨건
            </Typography>
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  );
};