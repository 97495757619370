import { useEffect, useState, useRef } from "react";
import { Routes, Route, Link, useNavigate} from "react-router-dom";
import ReactDOMServer from 'react-dom/server';
import { Box, CircularProgress, Container, Grid, IconButton, LinearProgress, Toolbar, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Item from './pages/Item';
import './App.css';

import { http } from './util';
import { MyLocation } from "@mui/icons-material";

const { naver } = window;
const mapOptions = {
  mapTypeControl: true,
  mapTypeControlOptions: {
      style: naver.maps.MapTypeControlStyle.BUTTON,
      position: naver.maps.Position.TOP_RIGHT
  },
  zoomControl: true,
  zoomControlOptions: {
      style: naver.maps.ZoomControlStyle.SMALL,
      position: naver.maps.Position.RIGHT_CENTER
  },
  scaleControl: true,
  scaleControlOptions: {
      position: naver.maps.Position.BOTTOM_RIGHT
  },
  mapDataControl: true,
  mapDataControlOptions: {
      position: naver.maps.Position.BOTTOM_LEFT
  }
};
const pageSize = 10;
const initialZoom = 15;
const locationBtnHtml = '<span class="iconify loc-button" data-icon="bx:bx-current-location"></span>';
// const LocationButton = () => (
//   ReactDOMServer.renderToString(
//     <IconButton sx={{bgcolor: '#fff', m: 1}}>
//       <MyLocationIcon />
//     </IconButton>
//   )
// )


function App() {
  const [pos, setPos] = useState([37.4975694, 127.0259273]);
  const [map, setMap] = useState();
  const [bound, setBound] = useState();
  const [hospitals, setHospitals] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [markers, setMarkers] = useState([]);
  const [selected, setSelected] = useState();
  const [treatment, setTreatment] = useState("4");
  const [searching, setSearching] = useState(false);
  const [loading, setLoading] = useState(true);
  const header = useRef(null);

  const navigate = useNavigate();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const headerHeight = header.current?.offsetHeight || 0;
  const mapHeight = mobile ? (window.innerHeight - headerHeight) / 2 : window.innerHeight - headerHeight;

  const updateBound = (goHome=true) => {
    let bound = map.getBounds();
    setPage(1);
    setBound([bound._ne.y, bound._ne.x, bound._sw.y, bound._sw.x]);
    if (goHome) {
      navigate('/');
    }
  };

  const updateCenter = (_center) => {
    const center = new naver.maps.LatLng(_center[0], _center[1]);
    map.setCenter(center);
    updateBound(false);
  }

  const getPos = () => {
    setSearching(true);
    navigator.geolocation.getCurrentPosition((res) => {
      const center = new naver.maps.LatLng(res.coords.latitude, res.coords.longitude);
      map.setCenter(center);
      updateBound();
      setSearching(false);
    }, (err) => {
      setSearching(false);
    });
  }

  const loadList = () => {
    setLoading(true);
    http.get('hair', {
      params: {
        left_top_lat: bound[0],
        left_top_lng: bound[1],
        right_bottom_lat: bound[2],
        right_bottom_lng: bound[3],
        treatment_name: treatment,
        page_size: 10,
        page_index: page
      }
    }).then((res) => {
      setHospitals(res.data.results);
      setTotal(res.data.total_count);
      setSelected();
      setLoading(false);
    })
  }

  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
    const center = new naver.maps.LatLng(pos[0], pos[1]);
    const _map = new naver.maps.Map('map', {
      center,
      zoom: initialZoom,
      ...mapOptions
    });
    setMap(_map);
  }, []);

  useEffect(() => {
    if (map) {
      if (window.location.pathname == "/") {
        updateBound();
      }
      naver.maps.Event.addListener(map, "dragend", function(e) {
        updateBound();
      });
      naver.maps.Event.addListener(map, "zoom_changed", function(e) {
        updateBound();
      });
      // naver.maps.Event.addListener(map, "click", function(e) {
      //   navigate('/');
      // });
    }
  }, [map]);

  useEffect(() => {
    if (bound) {
      loadList();
      map.autoResize();
    }
  }, [bound, treatment, page]);

  useEffect(() => {
    markers.forEach((marker) => {
      marker.setMap();
    })
    setMarkers(hospitals.map((hospital, idx) => {
      let marker = new naver.maps.Marker({
        position: new naver.maps.LatLng(hospital.location.Lat, hospital.location.Lng),
        map: map,
        title: hospital.name
      });
      naver.maps.Event.addListener(marker, 'click', e => {
        // setSelected(hospital);
        navigate(`/item/${hospital.id}`);
      })
      return marker;
    }));
  }, [hospitals]);

  return (
    <div className="App">
      <Box ref={header}>
        <Header />
      </Box>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Box id="map" style={{width: '100%', height: mapHeight }}></Box>
          {Boolean(navigator.geolocation) && <IconButton
            className='loc-button'
            variant="contained"
            color="primary"
            sx={{position: 'absolute', top: headerHeight + 10, left: 10, bgcolor: '#fff'}}
            onClick={getPos}
          >
            {searching ? <CircularProgress size={24} /> : <MyLocation />}
          </IconButton>}
        </Grid>
        <Grid item xs={12} md={4}>
          {loading && <LinearProgress />}
          <Container maxWidth="md" sx={{ overflow: 'auto', height: mapHeight || 'auto' }}>
            <Box sx={{minHeight: mobile ? mapHeight : mapHeight - 290}} pt={1}>
              <Routes>
                <Route path="/" element={
                  <Home
                    hospitals={hospitals}
                    total={Math.ceil(total / pageSize)}
                    page={page}
                    setPage={setPage}
                    selected={selected}
                    treatment={treatment}
                    setTreatment={setTreatment}
                    />
                  }
                />
                <Route
                  path="/item/:id"
                  element={<Item map={map} updateCenter={updateCenter} setLoading={setLoading}/>} 
                />
              </Routes>
            </Box>
            <ins 
              className="adsbygoogle"
              style={{ display: 'block' }}
              data-ad-client="ca-pub-5857936531233376"
              data-ad-slot="5097634656"
              data-ad-format="auto"
            />
          </Container>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
}

export default App;
