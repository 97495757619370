import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { http, fNumber } from '../util';
import { Map as MapIcon, Phone as PhoneIcon, Home as HomeIcon, EventNote, ArrowBack } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';

const { naver } = window;

export default function Item ({map, updateCenter, setLoading}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [hospital, setHospital] = useState();

  const goAddress = () => {
    updateCenter([hospital.location.Lat, hospital.location.Lng]);
  }

  const goBack = () => {
    navigate('/');
  }

  useEffect(() => {
    if (id) {
      setLoading(true);
      http.get(`hair/${id}`).then((res) => {
        setHospital(res.data);
        setLoaded(true);
        setLoading(false);
      });
    }
  }, [id]);

  useEffect(() => {
    if (hospital) {
      goAddress()
      const marker = new naver.maps.Marker({
        position: new naver.maps.LatLng(hospital.location.Lat, hospital.location.Lng),
        map: map,
        title: hospital.name,
        icon: {
          url: '/marker_icon.png',
          size: new naver.maps.Size(64, 64),
          scaledSize: new naver.maps.Size(64, 64),
          origin: new naver.maps.Point(0, 0),
          anchor: new naver.maps.Point(32, 64)
        },
        zIndex: 1000
      });

      return () => {
        marker.setMap();
      }
    }
  }, [hospital])

  if (!loaded) return <></>;
  return (
    <Box>
      <Helmet>
        <title>모발이식웨건 - {hospital.name}</title>
      </Helmet>
      <Button startIcon={<ArrowBack />} onClick={goBack}>목록으로</Button>
      <Box py={2}>
        <Stack direction="row" alignItems="center" my={1}>
          <Typography variant="h4">{hospital.name}</Typography>
          {hospital.url && <IconButton sx={{ml: 1}} onClick={() => window.open(hospital.url)}>
            <HomeIcon color="black" />
          </IconButton>}
        </Stack>
        <Stack direction="row" alignItems="center" my={1}>
          <MapIcon sx={{ mr: 1 }} />
          <Typography>{hospital.addr}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" my={1}>
          <PhoneIcon sx={{ mr: 1 }} />
          <Typography onClick={() => window.location.href =`tel:${hospital.telno}`}>{hospital.telno}</Typography>
        </Stack>
        {/* {hospital.url && <Stack direction="row" alignItems="center" my={1}>
          <HomeIcon sx={{ mr: 1 }} />
          <Typography onClick={() => window.location.href = hospital.url}>{hospital.url}</Typography>
        </Stack>} */}
      </Box>
      <Box py={2} mb={1}>
        <Stack direction="row" alignItems="center" my={1}>
          <EventNote sx={{ mr: 1 }} />
          <Typography variant="h5">진료 항목</Typography>
        </Stack>
        {hospital.Treatments.map((treatment) => {
          const prcStr = treatment.prcMin == treatment.prcMax ?
            `${fNumber(treatment.prcMax)}` : 
            `${fNumber(treatment.prcMin)} ~ ${fNumber(treatment.prcMax)}`
          return (
            <Box key={`treatment-${treatment.id}`} display="flex" flexDirection="row" justifyContent="space-between">
              <Typography paragraph>{treatment.name}</Typography>
              <Typography paragraph>{prcStr}원</Typography>
            </Box>
          )
        })}
      </Box>
    </Box>
  );
};